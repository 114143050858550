.overlay {
  /* background-color: #1e282c; */
  opacity: 0.95;
}

.sign {
  /* position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%; */
  /* background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      var(--side-purple),
      transparent
    ); */
  /* transform: translate(-50%, -50%); */
  /* letter-spacing: 2; */
  /* left: 50%;
  top: 50%; */
  /* text-transform: uppercase; */
  /* font-size: 6em; */
  /* color: var(--light-purple); */
  color: var(white);
  /* text-shadow: 0 0 0.6rem var(--light-purple), 0 0 1.5rem var(--mid-purple),
    -0.2rem 0.1rem 1rem var(--mid-purple), 0.2rem 0.1rem 1rem var(--mid-purple),
    0 -0.5rem 2rem var(--side-purple), 0 0.5rem 3rem var(--side-purple); */
  text-shadow: 0 0 2px rgba(202, 228, 225, 0.92),
    0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52),
    0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78),
    0 0 16px rgba(30, 132, 242, 0.92);
  animation: shine 70s forwards, flicker 30s infinite;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    /* color: var(--light-purple); */
    /* color: var(--light-purple); */
    color: var(white);
    /* text-shadow: 0 0 0.6rem var(--light-purple), 0 0 1.5rem var(--mid-purple),
      -0.2rem 0.1rem 1rem var(--mid-purple),
      0.2rem 0.1rem 1rem var(--mid-purple), 0 -0.5rem 2rem var(--side-purple),
      0 0.5rem 3rem var(--side-purple); */
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.92),
      0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52),
      0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78),
      0 0 16px rgba(30, 132, 242, 0.92);
  }
  28%,
  33% {
    /* color: var(--light-purple); */
    color: var(white);
    text-shadow: none;
  }
  82%,
  97% {
    /* color: var(--light-purple); */
    color: var(white);
    text-shadow: none;
  }
}

.flicker {
  animation: shine 7s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 7s forwards, blink 10s 1s infinite;
}

.slow-flicker {
  animation: shine 7s forwards, blink 1s 3s infinite;
}

@keyframes shine {
  0% {
    /* color: var(--light-purple); */
    color: var(white);
    text-shadow: none;
  }
  100% {
    /* color: var(--light-purple); */
    color: var(white);
    /* text-shadow: 0 0 0.6rem var(--light-purple), 0 0 1.5rem var(--mid-purple),
      -0.2rem 0.1rem 1rem var(--mid-purple),
      0.2rem 0.1rem 1rem var(--mid-purple), 0 -0.5rem 2rem var(--side-purple),
      0 0.5rem 3rem var(--side-purple); */
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.92),
      0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52),
      0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78),
      0 0 16px rgba(30, 132, 242, 0.92);
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

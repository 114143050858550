#cs_audio {
  display: none;
}

#cs_audioplayer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  background-color: #7d4d71;
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 6px;
  color: #fff;
}

.cs_audio_bar {
  background-color: #000;
  width: 100%;
  height: 6px;
  position: relative;
  cursor: pointer;
  transition: 0.1s;
}

.cs_audio_bar_loaded {
  background-color: #44363d;
  width: 0%;
  height: 6px;
  position: absolute;
  transition: 0.1s;
}
.cs_audio_bar_now {
  background-color: lime;
  width: 0%;
  height: 6px;
  position: absolute;
  z-index: 999;
  transition: 0.1s;
}
#cs_play_pause_btn {
  margin-left: 95px;
}

#cs_play_pause_btn i {
  font-size: 1.45em;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 4px;
}

.rhap_container {
  /* background-color: var(--main-purple); */
  /* background-color: transparent; */
  bottom: 0px;
  background-image: linear-gradient(to bottom, black, #532c79, black);
  border-color: transparent;
}

.rhap_main-controls button {
  color: lightgray;
}

.rhap_additional-controls button {
  color: lightgray;
}

.rhap_volume-controls button {
  color: lightgray;
}

.rhap_progress-container {
  color: lightgray;
}

.rhap_time {
  color: lightgray;
}

.rhap_progress-bar {
  background-color: lightgray;
}

.rhap_progress-indicator {
  /* background-color: var(--main-gray); */
  background-color: lightgray;
}

.rhap_volume-indicator {
  /* background-color: var(--main-gray); */
  background-color: lightgray;
}
